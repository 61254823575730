import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/core.module";
import {FormsModule} from "@angular/forms";
import {UiModule} from "../ui/ui.module";
import {UsuarioService} from "./services/usuario.service";
import {RouterModule, Routes} from "@angular/router";
import {MunicipioService} from "./services/municipio.service";
import {AtividadeService} from "./services/atividade.service";
import {PessoaService} from "./services/pessoa.service";
import {CEPService} from "./services/cep.service";
import {CabecalhoDocumentoComponent} from "./components/cabecalho-documento/cabecalho.documento.component";
import {ConfigMunicipioService} from "./services/config.municipio.service";
import {ArquivoService} from "./services/arquivo.service";
import {ExportService} from "./services/export.service";
import {TipoDeducaoService} from "./services/tipo.deducao.service";
import {ExigibilidadePadraoService} from "./services/exigibilidade.padrao.service";
import {FiscalService} from "./services/fiscal.service";
import {GrupoAtividadeService} from "./services/grupo.atividade.service";
import {AtividadeCTISSService} from "./services/atividade.ctiss.service";
import {AtividadeCNAEService} from "./services/atividade.cnae.service";
import {TipoCreditoService} from "./services/tipo.credito.service";
import {EncerramentoService} from './services/encerramento.service';
import {VinculoAtividadeService} from "./services/vinculo.atividade.service";
import {MunicipioVencimentoService} from "./services/municipio.vencimento.service";
import {ConfigBoletoService} from "./services/config.boleto.service";
import {ComunicadoService} from "./services/comunicado.service";
import {PessoaAutocompleteComponent} from "./components/pessoa-autocomplete/pessoa.autocomplete.component";
import {JustificativaIndeferimentoService} from "./services/justificativa.indeferimento.service";
import {FeriadoService} from './services/feriado.service';
import {SerieService} from "./services/serie.service";
import {TipoTributacaoService} from "./services/tipo.tributacao.service";
import {EstadoService} from "./services/estado.service";
import {MunicipioSelectorComponent} from "./components/municipio-selector/municipio.selector.component";
import {PessoaComponent} from "./components/pessoa/pessoa.component";
import {EnderecoComponent} from "./components/endereco/endereco.component";
import {CompetenciaSelectorComponent} from "./components/competencia-selector/competencia.selector.component";
import {AtividadeSelectorComponent} from "./components/atividade-selector/atividade.selector.component";
import {RegimeSelectorComponent} from "./components/regime-selector/regime.selector.component";
import {
  AtividadeCtissSelectorComponent
} from "./components/atividade-ctiss-selector/atividade.ctiss.selector.component";
import {RegimeDropDownComponent} from "./components/regime-drop-down/regime.drop.down.component";
import {
  NaturezaCadastroDropDownComponent
} from "./components/natureza-cadastro-drop-down/natureza.cadastro.drop.down.component";
import {FormatoRelatorioComponent} from "./components/formato-relatorio/formato.relatorio.component";
import {ReportListComponent} from "./components/report-list/report.list.component";
import {RelatorioAssincronoService} from "./services/relatorio.assincrono.service";
import {CatalogoEnderecoService} from "./services/catalogo.endereco.service";
import {ConfigNotaPremiadaPeriodoService} from "./services/config.nota.premiada.periodo.service";
import {AnexoSimplesSelectorComponent} from "./components/anexo-simples-selector/anexo.simples.selector.component";
import {ObraUnicaService} from "./services/obra.unica.service";

const routes: Routes = [

  // {path: "acesso-negado", component: AcessoNegadoPageComponent, canActivate: [AuthGuard]},
];

@NgModule({
  declarations: [
    AnexoSimplesSelectorComponent,
    AtividadeCtissSelectorComponent,
    AtividadeSelectorComponent,
    CabecalhoDocumentoComponent,
    CompetenciaSelectorComponent,
    EnderecoComponent,
    FormatoRelatorioComponent,
    MunicipioSelectorComponent,
    NaturezaCadastroDropDownComponent,
    PessoaComponent,
    PessoaAutocompleteComponent,
    RegimeDropDownComponent,
    RegimeSelectorComponent,
    ReportListComponent
  ],

  exports: [
    AnexoSimplesSelectorComponent,
    AtividadeCtissSelectorComponent,
    AtividadeSelectorComponent,
    CabecalhoDocumentoComponent,
    CompetenciaSelectorComponent,
    EnderecoComponent,
    FormatoRelatorioComponent,
    MunicipioSelectorComponent,
    NaturezaCadastroDropDownComponent,
    PessoaComponent,
    PessoaAutocompleteComponent,
    RegimeDropDownComponent,
    RegimeSelectorComponent,
    ReportListComponent
  ],

  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    UiModule,
    RouterModule.forChild(routes)
  ],

  providers: [
    ArquivoService,
    AtividadeService,
    AtividadeCNAEService,
    AtividadeCTISSService,
    CatalogoEnderecoService,
    CEPService,
    ComunicadoService,
    ConfigBoletoService,
    ConfigMunicipioService,
    ConfigNotaPremiadaPeriodoService,
    EncerramentoService,
    EstadoService,
    ExportService,
    ExigibilidadePadraoService,
    FeriadoService,
    FiscalService,
    GrupoAtividadeService,
    JustificativaIndeferimentoService,
    MunicipioService,
    MunicipioVencimentoService,
    PessoaService,
    RelatorioAssincronoService,
    SerieService,
    TipoCreditoService,
    TipoDeducaoService,
    TipoTributacaoService,
    UsuarioService,
    VinculoAtividadeService,
    ObraUnicaService
  ]
})

export class CommonsModule {
}
