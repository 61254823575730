import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("MonitorIntegracaoPortalNacionalTO")
export class MonitorIntegracaoPortalNacionalTO {

  @DateType()
  dataProcessamento: Date;

  chave: string;

  erro: string;

  notaFiscal: string;

  operacao: string;

  docNomePrestador: string;

  localEmissao: string;
}
