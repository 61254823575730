import {JacksonTypeFactory} from "@sonner/jackson-service-v2";
import {AIDF} from "./app/classes/a.i.d.f";
import {AcaoFiscalTipoDocumento} from "./app/classes/acao.fiscal.tipo.documento";
import {Administrador} from "./app/classes/administrador";
import {AgendaFiscalizacao} from "./app/classes/agenda.fiscalizacao";
import {Agendamento} from "./app/classes/agendamento";
import {AlertaFiscalAliquotaSimplesIncorreta} from "./app/classes/alerta.fiscal.aliquota.simples.incorreta";
import {AlertaFiscalDivergenciaMovimentacaoReceita} from "./app/classes/alerta.fiscal.divergencia.movimentacao.receita";
import {AlertaFiscalMovimentacaoAtipica} from "./app/classes/alerta.fiscal.movimentacao.atipica";
import {AlertaFiscalQuedaArrecadacao} from "./app/classes/alerta.fiscal.queda.arrecadacao";
import {AlertaFiscalRegimeSimplesIncorreto} from "./app/classes/alerta.fiscal.regime.simples.incorreto";
import {AlertaFiscalTributacaoExcessivaFora} from "./app/classes/alerta.fiscal.tributacao.excessiva.fora";
import {AlertaFiscal} from "./app/classes/alerta.fiscal";
import {AliquotaProgressivaAtividadeExclui} from "./app/classes/aliquota.progressiva.atividade.exclui";
import {AliquotaProgressivaConfiguracao} from "./app/classes/aliquota.progressiva.configuracao";
import {AliquotaProgressivaItem} from "./app/classes/aliquota.progressiva.item";
import {AliquotaProgressivaTabela} from "./app/classes/aliquota.progressiva.tabela";
import {AlunoCurso} from "./app/classes/aluno.curso";
import {Aluno} from "./app/classes/aluno";
import {ArquivoImportacaoFiscal} from "./app/classes/arquivo.importacao.fiscal";
import {ArquivoImportacao} from "./app/classes/arquivo.importacao";
import {Arquivo} from "./app/classes/arquivo";
import {AtividadeCNAE} from "./app/classes/atividade.c.n.a.e";
import {AtividadeCTISS} from "./app/classes/atividade.c.t.i.s.s";
import {AtividadeDimensao} from "./app/classes/atividade.dimensao";
import {AtividadeHistorico} from "./app/classes/atividade.historico";
import {AtividadeTabelaIBPT} from "./app/classes/atividade.tabela.i.b.p.t";
import {Atividade} from "./app/classes/atividade";
import {AtividadeVencimento} from "./app/classes/atividade.vencimento";
import {AutorizacaoImportacaoNFeGuia} from "./app/classes/autorizacao.importacao.n.fe.guia";
import {AutorizacaoImportacaoNFe} from "./app/classes/autorizacao.importacao.n.fe";
import {AutorizacaoNFe} from "./app/classes/autorizacao.n.fe";
import {BancoDependencia} from "./app/classes/banco.dependencia";
import {Banco} from "./app/classes/banco";
import {BloqueioFaltaPagamentoCompetenciaIgnorada} from "./app/classes/bloqueio.falta.pagamento.competencia.ignorada";
import {BloqueioMovimentacao} from "./app/classes/bloqueio.movimentacao";
import {CEP} from "./app/classes/c.e.p";
import {CalculoEncerramento} from "./app/classes/calculo.encerramento";
import {CalculoGuiaAvulsa} from "./app/classes/calculo.guia.avulsa";
import {Cartorio} from "./app/classes/cartorio";
import {CatalogoEndereco} from "./app/classes/catalogo.endereco";
import {CodigoTipoTributacao} from "./app/classes/codigo.tipo.tributacao";
import {CompetenciaDimensao} from "./app/classes/competencia.dimensao";
import {Competencia} from "./app/classes/competencia";
import {Comunicado} from "./app/classes/comunicado";
import {ConfigBoleto} from "./app/classes/config.boleto";
import {ConfigGuia} from "./app/classes/config.guia";
import {ConfigLivro} from "./app/classes/config.livro";
import {ConfigMunicipio} from "./app/classes/config.municipio";
import {ConfigNotaPremiadaDocumentosExcluidos} from "./app/classes/config.nota.premiada.documentos.excluidos";
import {ConfigNotaPremiadaPeriodo} from "./app/classes/config.nota.premiada.periodo";
import {ConfigNotaPremiada} from "./app/classes/config.nota.premiada";
import {ConfigRegimeTipoTributacao} from "./app/classes/config.regime.tipo.tributacao";
import {ConfigSolicitacoes} from "./app/classes/config.solicitacoes";
import {ConfiguracaoPessoal} from "./app/classes/configuracao.pessoal";
import {ConsolidacaoDwLog} from "./app/classes/consolidacao.dw.log";
import {Construtora} from "./app/classes/construtora";
import {ContaCosif} from "./app/classes/conta.cosif";
import {Contador} from "./app/classes/contador";
import {ControleNumeracaoGuia} from "./app/classes/controle.numeracao.guia";
import {ControleNumeracaoNota} from "./app/classes/controle.numeracao.nota";
import {ControleTSA} from "./app/classes/controle.t.s.a";
import {Convite} from "./app/classes/convite";
import {CorrecaoIndiceStrategy} from "./app/classes/correcao.indice.strategy";
import {CorrecaoStrategy} from "./app/classes/correcao.strategy";
import {Cotacao} from "./app/classes/cotacao";
import {CreditoAReceberAtividade} from "./app/classes/credito.a.receber.atividade";
import {CreditoAReceber} from "./app/classes/credito.a.receber";
import {CreditoTomadorConfig} from "./app/classes/credito.tomador.config";
import {CreditoTomador} from "./app/classes/credito.tomador";
import {Credito} from "./app/classes/credito";
import {Curso} from "./app/classes/curso";
import {CursoValor} from "./app/classes/curso.valor";
import {DadosCadastro} from "./app/classes/dados.cadastro";
import {DadosEndereco} from "./app/classes/dados.endereco";
import {DadosPessoa} from "./app/classes/dados.pessoa";
import {Debito} from "./app/classes/debito";
import {DeclaracaoIncorretaSimples} from "./app/classes/declaracao.incorreta.simples";
import {DeclaracaoNF} from "./app/classes/declaracao.n.f";
import {DeducaoSolicitacaoAlteracaoNFe} from "./app/classes/deducao.solicitacao.alteracao.n.fe";
import {Deducao} from "./app/classes/deducao";
import {Denuncia} from "./app/classes/denuncia";
import {DesifApuracaoMensalConsolidado} from "./app/classes/desif.apuracao.mensal.consolidado";
import {DesifApuracaoMensalDependencia} from "./app/classes/desif.apuracao.mensal.dependencia";
import {DesifApuracaoMensalSubtitulo} from "./app/classes/desif.apuracao.mensal.subtitulo";
import {DesifApuracaoMensal} from "./app/classes/desif.apuracao.mensal";
import {DesifCodigoTributacaoAtividade} from "./app/classes/desif.codigo.tributacao.atividade";
import {DesifCodigoTributacao} from "./app/classes/desif.codigo.tributacao";
import {DesifConta} from "./app/classes/desif.conta";
import {DesifDemonstrativoContabilBalanceteAnalitico} from "./app/classes/desif.demonstrativo.contabil.balancete.analitico";
import {DesifDemonstrativoContabilDependencia} from "./app/classes/desif.demonstrativo.contabil.dependencia";
import {DesifDemonstrativoContabilRateioInterno} from "./app/classes/desif.demonstrativo.contabil.rateio.interno";
import {DesifDemonstrativoContabil} from "./app/classes/desif.demonstrativo.contabil";
import {DesifDemonstrativoLancamentoContabil} from "./app/classes/desif.demonstrativo.lancamento.contabil";
import {DesifEventoContabil} from "./app/classes/desif.evento.contabil";
import {DesifImportacaoArquivo} from "./app/classes/desif.importacao.arquivo";
import {DesifPlanoConta} from "./app/classes/desif.plano.conta";
import {DesifServicoVariavel} from "./app/classes/desif.servico.variavel";
import {DesifTarifa} from "./app/classes/desif.tarifa";
import {DesifTarifaVariavel} from "./app/classes/desif.tarifa.variavel";
import {DiaDimensao} from "./app/classes/dia.dimensao";
import {Documento} from "./app/classes/documento";
import {Duvida} from "./app/classes/duvida";
import {Empresa} from "./app/classes/empresa";
import {EncerramentoAutomaticoDetalhamento} from "./app/classes/encerramento.automatico.detalhamento";
import {EncerramentoAutomatico} from "./app/classes/encerramento.automatico";
import {EncerramentoDesconto} from "./app/classes/encerramento.desconto";
import {EncerramentoDocumento} from "./app/classes/encerramento.documento";
import {EncerramentoMemoria} from "./app/classes/encerramento.memoria";
import {Encerramento} from "./app/classes/encerramento";
import {Endereco} from "./app/classes/endereco";
import {Ensino} from "./app/classes/ensino";
import {ErroImportWebISS} from "./app/classes/erro.import.web.i.s.s";
import {EscritBanco} from "./app/classes/escrit.banco";
import {EscritCartorio} from "./app/classes/escrit.cartorio";
import {EscritEspecial} from "./app/classes/escrit.especial";
import {EscritPapel} from "./app/classes/escrit.papel";
import {EscritPedagio} from "./app/classes/escrit.pedagio";
import {EscritTransporteArquivo} from "./app/classes/escrit.transporte.arquivo";
import {EscritTransporteTarifa} from "./app/classes/escrit.transporte.tarifa";
import {EscritTransporteTarifaValor} from "./app/classes/escrit.transporte.tarifa.valor";
import {EscritTransporte} from "./app/classes/escrit.transporte";
import {EscrituracaoObraUnica} from "./app/classes/escrituracao.obra.unica";
import {EspecieAIDF} from "./app/classes/especie.a.i.d.f";
import {Estado} from "./app/classes/estado";
import {EstatisticaBancaria} from "./app/classes/estatistica.bancaria";
import {EstatisticaVerbete} from "./app/classes/estatistica.verbete";
import {Evento} from "./app/classes/evento";
import {ExigibilidadePadrao} from "./app/classes/exigibilidade.padrao";
import {FaixaMultaStrategy} from "./app/classes/faixa.multa.strategy";
import {FaixaMulta} from "./app/classes/faixa.multa";
import {FatoGeradorCreditoAReceber} from "./app/classes/fato.gerador.credito.a.receber";
import {Feriado} from "./app/classes/feriado";
import {Fiscal} from "./app/classes/fiscal";
import {FuncionarioEmpresas} from "./app/classes/funcionario.empresas";
import {Funcionario} from "./app/classes/funcionario";
import {Gestor} from "./app/classes/gestor";
import {Grafica} from "./app/classes/grafica";
import {GrupoAtividade} from "./app/classes/grupo.atividade";
import {GuiaAvulsaConfigBoleto} from "./app/classes/guia.avulsa.config.boleto";
import {GuiaAvulsa} from "./app/classes/guia.avulsa";
import {GuiaMensagem} from "./app/classes/guia.mensagem";
import {IBPTArquivo} from "./app/classes/i.b.p.t.arquivo";
import {IBPTAtividade} from "./app/classes/i.b.p.t.atividade";
import {IBPTTabela} from "./app/classes/i.b.p.t.tabela";
import {IBPTVinculoAtividade} from "./app/classes/i.b.p.t.vinculo.atividade";
import {ImportacaoDWMetaDado} from "./app/classes/importacao.d.w.meta.dado";
import {ImportacaoPGDASD} from "./app/classes/importacao.p.g.d.a.sd";
import {Indice} from "./app/classes/indice";
import {Infracao} from "./app/classes/infracao";
import {InstituicaoBancaria} from "./app/classes/instituicao.bancaria";
import {IsencaoGuia} from "./app/classes/isencao.guia";
import {ItemEscrit} from "./app/classes/item.escrit";
import {ItemGuiaAvulsa} from "./app/classes/item.guia.avulsa";
import {ItemNotaFiscalHistorico} from "./app/classes/item.nota.fiscal.historico";
import {ItemNotaFiscal} from "./app/classes/item.nota.fiscal";
import {ItemReciboLocacao} from "./app/classes/item.recibo.locacao";
import {ItemSolicitacaoAlteracaoNFe} from "./app/classes/item.solicitacao.alteracao.n.f.e";
import {ItemTabelaIBPT} from "./app/classes/item.tabela.i.b.p.t";
import {JuroDiarioStrategy} from "./app/classes/juro.diario.strategy";
import {JuroMensalStrategy} from "./app/classes/juro.mensal.strategy";
import {JuroSelicStrategy} from "./app/classes/juro.selic.strategy";
import {JurosStrategy} from "./app/classes/juros.strategy";
import {JustificativaIndeferimento} from "./app/classes/justificativa.indeferimento";
import {LogImportacao} from "./app/classes/log.importacao";
import {Logradouro} from "./app/classes/logradouro";
import {LoteRps} from "./app/classes/lote.rps";
import {LoteTre} from "./app/classes/lote.tre";
import {MediaMovimentacao} from "./app/classes/media.movimentacao";
import {Mensagem} from "./app/classes/mensagem";
import {MultaDiariaStrategy} from "./app/classes/multa.diaria.strategy";
import {MultaInfracao} from "./app/classes/multa.infracao";
import {MultaStrategy} from "./app/classes/multa.strategy";
import {MunicipioCertificado} from "./app/classes/municipio.certificado";
import {MunicipioDimensao} from "./app/classes/municipio.dimensao";
import {Municipio} from "./app/classes/municipio";
import {MunicipioVencimento} from "./app/classes/municipio.vencimento";
import {NotaFiscalGuiaAvulsa} from "./app/classes/nota.fiscal.guia.avulsa";
import {NotaFiscalHistorico} from "./app/classes/nota.fiscal.historico";
import {NotaFiscalOrigem} from "./app/classes/nota.fiscal.origem";
import {NotaFiscal} from "./app/classes/nota.fiscal";
import {NotaPremiada} from "./app/classes/nota.premiada";
import {NotificacaoSistema} from "./app/classes/notificacao.sistema";
import {ObraCorrecaoNotaFiscal} from "./app/classes/obra.correcao.nota.fiscal";
import {ObraNotaFiscal} from "./app/classes/obra.nota.fiscal";
import {ObraPrestador} from "./app/classes/obra.prestador";
import {Obra} from "./app/classes/obra";
import {ObraUnica} from "./app/classes/obra.unica";
import {OcorrenciaTermoAutoInfracao} from "./app/classes/ocorrencia.termo.auto.infracao";
import {OrdemServicoArquivo} from "./app/classes/ordem.servico.arquivo";
import {OrdemServicoItem} from "./app/classes/ordem.servico.item";
import {OrdemServico} from "./app/classes/ordem.servico";
import {PAGPagamento} from "./app/classes/p.a.g.pagamento";
import {PGDASDApuracaoFilialAtividadeDetalhe} from "./app/classes/p.g.d.a.s.d.apuracao.filial.atividade.detalhe";
import {PGDASDApuracaoFilialAtividade} from "./app/classes/p.g.d.a.s.d.apuracao.filial.atividade";
import {PGDASDApuracaoFilial} from "./app/classes/p.g.d.a.s.d.apuracao.filial";
import {PGDASDApuracaoReceita} from "./app/classes/p.g.d.a.s.d.apuracao.receita";
import {PGDASDApuracao} from "./app/classes/p.g.d.a.s.d.apuracao";
import {PGDASDDASDetalhe} from "./app/classes/p.g.d.a.s.d.d.a.s.detalhe";
import {PGDASDDAS} from "./app/classes/p.g.d.a.s.d.d.a.s";
import {PagamentoSIAFI} from "./app/classes/pagamento.s.i.a.f.i";
import {Pagamento} from "./app/classes/pagamento";
import {Pais} from "./app/classes/pais";
import {Papel} from "./app/classes/papel";
import {ParcelamentoSimplesConsolidacao} from "./app/classes/parcelamento.simples.consolidacao";
import {ParcelamentoSimplesDebito} from "./app/classes/parcelamento.simples.debito";
import {ParcelamentoSimplesPagamento} from "./app/classes/parcelamento.simples.pagamento";
import {ParcelamentoSimples} from "./app/classes/parcelamento.simples";
import {Pedagio} from "./app/classes/pedagio";
import {PerfilDescontoBaseCalculo} from "./app/classes/perfil.desconto.base.calculo";
import {PerfilDescontoImpostoNfe} from "./app/classes/perfil.desconto.imposto.nfe";
import {PessoaDescontoEncerramento} from "./app/classes/pessoa.desconto.encerramento";
import {PessoaExigibilidade} from "./app/classes/pessoa.exigibilidade";
import {PessoaMigracaoGrp} from "./app/classes/pessoa.migracao.grp";
import {PessoaPerfilDescontoBaseCalculo} from "./app/classes/pessoa.perfil.desconto.base.calculo";
import {PessoaPerfilDescontoImpostoNfe} from "./app/classes/pessoa.perfil.desconto.imposto.nfe";
import {Pessoa} from "./app/classes/pessoa";
import {Preferencias} from "./app/classes/preferencias";
import {ProcessoAssincrono} from "./app/classes/processo.assincrono";
import {ProcessoExportacaoSimplesDetalhe} from "./app/classes/processo.exportacao.simples.detalhe";
import {ProcessoExportacaoSimples} from "./app/classes/processo.exportacao.simples";
import {ProcessoMetadata} from "./app/classes/processo.metadata";
import {ReceitaRealizadaAtividade} from "./app/classes/receita.realizada.atividade";
import {ReceitaRealizada} from "./app/classes/receita.realizada";
import {ReciboLocacao} from "./app/classes/recibo.locacao";
import {Recibo} from "./app/classes/recibo";
import {RegimeCompetencia} from "./app/classes/regime.competencia";
import {RegimeISS} from "./app/classes/regime.i.s.s";
import {RelacaoNotaEscrit} from "./app/classes/relacao.nota.escrit";
import {RelacaoObraNotaEscrit} from "./app/classes/relacao.obra.nota.escrit";
import {Relacionamento} from "./app/classes/relacionamento";
import {RelatorioAssincrono} from "./app/classes/relatorio.assincrono";
import {RelatorioMetadata} from "./app/classes/relatorio.metadata";
import {Relatorios} from "./app/classes/relatorios";
import {Responsavel} from "./app/classes/responsavel";
import {RotinaAgendamentoLog} from "./app/classes/rotina.agendamento.log";
import {RotinaAgendamentoParametro} from "./app/classes/rotina.agendamento.parametro";
import {RotinaAgendamento} from "./app/classes/rotina.agendamento";
import {SaldoCompetencia} from "./app/classes/saldo.competencia";
import {Serie} from "./app/classes/serie";
import {ServicoBancario} from "./app/classes/servico.bancario";
import {ServicoEspecialGrupo} from "./app/classes/servico.especial.grupo";
import {ServicoEspecial} from "./app/classes/servico.especial";
import {ServicoEspecialValor} from "./app/classes/servico.especial.valor";
import {SituacaoItem} from "./app/classes/situacao.item";
import {SolicitacaoAIDF} from "./app/classes/solicitacao.a.i.d.f";
import {SolicitacaoAbatimentoObra} from "./app/classes/solicitacao.abatimento.obra";
import {SolicitacaoAlteracaoNFe} from "./app/classes/solicitacao.alteracao.n.fe";
import {SolicitacaoArquivo} from "./app/classes/solicitacao.arquivo";
import {SolicitacaoAutenticacaoLivroFiscal} from "./app/classes/solicitacao.autenticacao.livro.fiscal";
import {SolicitacaoCadastroEventual} from "./app/classes/solicitacao.cadastro.eventual";
import {SolicitacaoComJustificativa} from "./app/classes/solicitacao.com.justificativa";
import {SolicitacaoDadosPessoa} from "./app/classes/solicitacao.dados.pessoa";
import {SolicitacaoGenerica} from "./app/classes/solicitacao.generica";
import {SolicitacaoImportacaoNFE} from "./app/classes/solicitacao.importacao.n.f.e";
import {SolicitacaoNFe} from "./app/classes/solicitacao.n.fe";
import {SolicitacaoPerfilDescontoNfe} from "./app/classes/solicitacao.perfil.desconto.n.fe";
import {SolicitacaoSenha} from "./app/classes/solicitacao.senha";
import {Solicitacao} from "./app/classes/solicitacao";
import {SolicitacaoUsuario} from "./app/classes/solicitacao.usuario";
import {Suporte} from "./app/classes/suporte";
import {TNCAgendamentoExecLog} from "./app/classes/t.n.c.agendamento.exec.log";
import {TNCAgendamento} from "./app/classes/t.n.c.agendamento";
import {TNCAlertaMigracao} from "./app/classes/t.n.c.alerta.migracao";
import {TNCCadastroMigracaoPendente} from "./app/classes/t.n.c.cadastro.migracao.pendente";
import {TNCError} from "./app/classes/t.n.c.error";
import {TNCGlobalConfig} from "./app/classes/t.n.c.global.config";
import {TNCGlobalScript} from "./app/classes/t.n.c.global.script";
import {TNCMunicipioConfig} from "./app/classes/t.n.c.municipio.config";
import {TNCPropriedade} from "./app/classes/t.n.c.propriedade";
import {TNCSignal} from "./app/classes/t.n.c.signal";
import {TNCStatus} from "./app/classes/t.n.c.status";
import {TNCSyncScript} from "./app/classes/t.n.c.sync.script";
import {TabelaSimplesItem} from "./app/classes/tabela.simples.item";
import {TabelaSimples} from "./app/classes/tabela.simples";
import {Tarefa} from "./app/classes/tarefa";
import {Telefone} from "./app/classes/telefone";
import {TermoAutoInfracao} from "./app/classes/termo.auto.infracao";
import {TermoEncerramentoFiscalizacao} from "./app/classes/termo.encerramento.fiscalizacao";
import {TermoExclusaoSimples} from "./app/classes/termo.exclusao.simples";
import {TermoInicioFiscalizacao} from "./app/classes/termo.inicio.fiscalizacao";
import {TextoTermoFiscal} from "./app/classes/texto.termo.fiscal";
import {TipoAbatimentoObraContribuinte} from "./app/classes/tipo.abatimento.obra.contribuinte";
import {TipoAbatimentoObra} from "./app/classes/tipo.abatimento.obra";
import {TipoBloqueioMovimentacao} from "./app/classes/tipo.bloqueio.movimentacao";
import {TipoCredito} from "./app/classes/tipo.credito";
import {TipoDeducao} from "./app/classes/tipo.deducao";
import {TipoDescontoBaseCalculo} from "./app/classes/tipo.desconto.base.calculo";
import {TipoEstabelecimentoCartorio} from "./app/classes/tipo.estabelecimento.cartorio";
import {TipoServicoCartorio} from "./app/classes/tipo.servico.cartorio";
import {TipoTributacao} from "./app/classes/tipo.tributacao";
import {AlertaFiscalTO} from "./app/classes/to/alerta.fiscal.to";
import {AliquotaSimplesTO} from "./app/classes/to/aliquota.simples.to";
import {AtendimentoTO} from "./app/classes/to/atendimento.to";
import {AtividadeTO} from "./app/classes/to/atividade.to";
import {BilheteNotaPremiadaContribuinteDetalheTO} from "./app/classes/to/bilhete.nota.premiada.contribuinte.detalhe.to";
import {BilheteNotaPremiadaContribuinteTO} from "./app/classes/to/bilhete.nota.premiada.contribuinte.to";
import {BilheteNotaPremiadaPeriodoTO} from "./app/classes/to/bilhete.nota.premiada.periodo.to";
import {BilheteNotaPremiadaTO} from "./app/classes/to/bilhete.nota.premiada.to";
import {CalculoCorrecaoTO} from "./app/classes/to/calculo.correcao.to";
import {CalculoGuiaAvulsaTO} from "./app/classes/to/calculo.guia.avulsa.to";
import {CancelaNotaLoteTO} from "./app/classes/to/cancela.nota.lote.to";
import {CatalogoEnderecoTO} from "./app/classes/to/catalogo.endereco.to";
import {ConfigNotaPremiadaPeriodoTO} from "./app/classes/to/config.nota.premiada.periodo.to";
import {ConfigNotaPremiadaTO} from "./app/classes/to/config.nota.premiada.to";
import {ConfiguracaoAcessoTO} from "./app/classes/to/configuracao.acesso.to";
import {ConfiguracaoBackupTO} from "./app/classes/to/configuracao.backup.to";
import {ConfiguracaoConstrucaoCivilTO} from "./app/classes/to/configuracao.construcao.civil.to";
import {ConfiguracaoContribuinteTO} from "./app/classes/to/configuracao.contribuinte.to";
import {ConfiguracaoMenuTO} from "./app/classes/to/configuracao.menu.to";
import {ConfiguracaoMigradorTO} from "./app/classes/to/configuracao.migrador.to";
import {ConfiguracaoMunicipioTO} from "./app/classes/to/configuracao.municipio.to";
import {ConfiguracaoNotificacaoTO} from "./app/classes/to/configuracao.notificacao.to";
import {ConfiguracaoSerieTO} from "./app/classes/to/configuracao.serie.to";
import {ConfiguracaoTipoTributacaoTO} from "./app/classes/to/configuracao.tipo.tributacao.to";
import {ConfiguracaoUnidadeTO} from "./app/classes/to/configuracao.unidade.to";
import {ConsultaContribuinteAutorizacoesTO} from "./app/classes/to/consulta.contribuinte.autorizacoes.to";
import {ConsultaContribuinteLivrosTO} from "./app/classes/to/consulta.contribuinte.livros.to";
import {ConsultaContribuinteNotaEscritTO} from "./app/classes/to/consulta.contribuinte.nota.escrit.to";
import {ConsultaContribuinteTO} from "./app/classes/to/consulta.contribuinte.to";
import {ConsultaDASTO} from "./app/classes/to/consulta.das.to";
import {ConsultaNotaFiscalHistoricoTO} from "./app/classes/to/consulta.nota.fiscal.historico.to";
import {ConsultaSimplesNacionalResumoAtividadeTO} from "./app/classes/to/consulta.simples.nacional.resumo.atividade.to";
import {ConsultaSimplesNacionalTO} from "./app/classes/to/consulta.simples.nacional.to";
import {ContribuinteEncerramentoResumidoTO} from "./app/classes/to/contribuinte.encerramento.resumido.to";
import {ControleExportacaoTO} from "./app/classes/to/controle.exportacao.to";
import {CreditoTO} from "./app/classes/to/credito.to";
import {CreditosTO} from "./app/classes/to/creditos.to";
import {CursoTO} from "./app/classes/to/curso.to";
import {EncerramentoAnoMesTO} from "./app/classes/to/encerramento.ano.mes.to";
import {EncerramentoAutomaticoTO} from "./app/classes/to/encerramento.automatico.to";
import {EncerramentoDetalheMesTO} from "./app/classes/to/encerramento.detalhe.mes.to";
import {EncerramentoDetalheTO} from "./app/classes/to/encerramento.detalhe.to";
import {EncerramentoMesTO} from "./app/classes/to/encerramento.mes.to";
import {EncerramentoSimplificadoTO} from "./app/classes/to/encerramento.simplificado.to";
import {EncerramentosAutomaticoDetalhamentoTO} from "./app/classes/to/encerramentos.automatico.detalhamento.to";
import {EncerramentosAutomaticoDetalheTO} from "./app/classes/to/encerramentos.automatico.detalhe.to";
import {EncerramentoOficioDetalheTO} from "./app/classes/to/encerramentos.oficio.detalhe.to";
import {EncerramentoOficioTO} from "./app/classes/to/encerramentos.oficio.to";
import {EscritCartorioTO} from "./app/classes/to/escrit.cartorio.to";
import {EscritPedagioTO} from "./app/classes/to/escrit.pedagio.to";
import {EscritTransporteArquivoResumoTO} from "./app/classes/to/escrit.transporte.arquivo.resumo.to";
import {EscritTransporteArquivoTO} from "./app/classes/to/escrit.transporte.arquivo.to";
import {EscritTransporteTarifaTO} from "./app/classes/to/escrit.transporte.tarifa.to";
import {EscrituracaoTO} from "./app/classes/to/escrituracao.to";
import {EstatisticaAcessoTO} from "./app/classes/to/estatistica.acesso.to";
import {ExportTO} from "./app/classes/to/export.to";
import {FaixaValoresRecebidosSimplesNacionalTO} from "./app/classes/to/faixa.valores.recebidos.simples.nacional.to";
import {FiscalMenuTO} from "./app/classes/to/fiscal.menu.to";
import {GuiaMesTO} from "./app/classes/to/guia.mes.to";
import {GuiaSimplificadaTO} from "./app/classes/to/guia.simplificada.to";
import {InicialMenuTO} from "./app/classes/to/inicial.menu.to";
import {InstanceMonitorTO} from "./app/classes/to/instance.monitor.to";
import {MonitorInstituicaoEnsinoTO} from "./app/classes/to/monitor.instituicao.ensino.to";
import {MonitorIntegracaoPortalNacionalTO} from "./app/classes/to/monitor.integracao.portal.nacional.to";
import {MonitorMunicipioTabelaTO} from "./app/classes/to/monitor.municipio.tabela.to";
import {MonitorMunicipioTO} from "./app/classes/to/monitor.municipio.to";
import {MonitorNotaPremiadaPrestadoresTO} from "./app/classes/to/monitor.nota.premiada.prestadores.to";
import {MonitorNotaPremiadaTO} from "./app/classes/to/monitor.nota.premiada.to";
import {NotaDeducaoDocumentoTO} from "./app/classes/to/nota.deducao.documento.to";
import {NotaDeducaoTO} from "./app/classes/to/nota.deducao.to";
import {NotaEnsinoAlunoTO} from "./app/classes/to/nota.ensino.aluno.to";
import {NotaEnsinoTO} from "./app/classes/to/nota.ensino.to";
import {NotaEscritMesTO} from "./app/classes/to/nota.escrit.mes.to";
import {NotaEscritTO} from "./app/classes/to/nota.escrit.to";
import {NotaFiscalSimplificadaTO} from "./app/classes/to/nota.fiscal.simplificada.to";
import {NotaFiscalTO} from "./app/classes/to/nota.fiscal.to";
import {OrdemServicoTO} from "./app/classes/to/ordem.servico.to";
import {ParcelamentoConsolidacaoTO} from "./app/classes/to/parcelamento.consolidacao.to";
import {ParcelamentoSimplesTO} from "./app/classes/to/parcelamento.simples.to";
import {PessoaDescontoEncerramentoTO} from "./app/classes/to/pessoa.desconto.encerramento.to";
import {PessoaSimplesTO} from "./app/classes/to/pessoa.simples.to";
import {PessoaSimplificadaTO} from "./app/classes/to/pessoa.simplificada.to";
import {PGDASDApuracaoTO} from "./app/classes/to/pgdasd.apuracao.to";
import {PGDASDDASTO} from "./app/classes/to/pgdasd.das.to";
import {PgdasdTO} from "./app/classes/to/pgdasd.to";
import {PrimeiroAcessoTO} from "./app/classes/to/primeiro.acesso.to";
import {ProcessoExportacaoSimplesTO} from "./app/classes/to/processo.exportacao.simples.to";
import {ReciboLocacaoTO} from "./app/classes/to/recibo.locacao.to";
import {ReciboTO} from "./app/classes/to/recibo.to";
import {RotinaAgendamentoTO} from "./app/classes/to/rotina.agendamento.to";
import {SaldoCompetenciaTO} from "./app/classes/to/saldo.competencia.to";
import {SIAFIPagamentosCompetenciaTO} from "./app/classes/to/siafi.pagamentos.competencia.to";
import {SIAFIPagamentosTO} from "./app/classes/to/siafi.pagamentos.to";
import {SolicitacaoAIDFTO} from "./app/classes/to/solicitacao.aidf.to";
import {SolicitacaoAlteracaoNFeTO} from "./app/classes/to/solicitacao.alteracao.nfe.to";
import {SolicitacaoArquivoTO} from "./app/classes/to/solicitacao.arquivo.to";
import {SolicitacaoAutenticacaoLivroFiscalTO} from "./app/classes/to/solicitacao.autenticacao.livro.fiscal.to";
import {SolicitacaoCancelamentoEncerramentoTO} from "./app/classes/to/solicitacao.cancelamento.encerramento.to";
import {SolicitacaoCancelamentoNFeTO} from "./app/classes/to/solicitacao.cancelamento.nfe.to";
import {SolicitacaoImportacaoNFETO} from "./app/classes/to/solicitacao.importacao.nfe.to";
import {SolicitacaoIndeferidaTO} from "./app/classes/to/solicitacao.indeferida.to";
import {SolicitacaoNFeTO} from "./app/classes/to/solicitacao.nfe.to";
import {SqlInterativoRow} from "./app/classes/to/sql.interativo.row";
import {SqlInterativoTitle} from "./app/classes/to/sql.interativo.title";
import {SqlInterativoTO} from "./app/classes/to/sql.interativo.to";
import {SqlInterativoValorTO} from "./app/classes/to/sql.interativo.valor.to";
import {TNCAgendamentoExecTO} from "./app/classes/to/tnc.agendamento.exec.to";
import {TNCDocumentoExportadosTO} from "./app/classes/to/tnc.documento.exportados.to";
import {TNCDocumentoPendentesTO} from "./app/classes/to/tnc.documento.pendentes.to";
import {TopicoTO} from "./app/classes/to/topico.to";
import {UsuarioTO} from "./app/classes/to/usuario.to";
import {VinculoAtividadeAnexoSimplesTO} from "./app/classes/to/vinculo.atividade.anexo.simples.to";
import {Topico} from "./app/classes/topico";
import {Transporte} from "./app/classes/transporte";
import {UnidadeItem} from "./app/classes/unidade.item";
import {Usuario} from "./app/classes/usuario";
import {ValorFolhaPagamento} from "./app/classes/valor.folha.pagamento";
import {ValorTabelaIBPT} from "./app/classes/valor.tabela.i.b.p.t";
import {Verbete} from "./app/classes/verbete";
import {VinculoAtividadeCNAE} from "./app/classes/vinculo.atividade.c.n.a.e";
import {VinculoAtividade} from "./app/classes/vinculo.atividade";
import {VinculoCnaeCtiss} from "./app/classes/vinculo.cnae.ctiss";
import {VinculoDocumento} from "./app/classes/vinculo.documento";
import {VinculoPessoaBancoDesif} from "./app/classes/vinculo.pessoa.banco.desif";
import {WebServiceArquivo} from "./app/classes/web.service.arquivo";
import {AuthErrorTO} from "./app/modules/core/classes/auth.error.to";
import {AuthResponseTO} from "./app/modules/core/classes/auth.response.to";
import {ExceptionInfo} from "./app/modules/core/classes/exception.info";
import {MenuMapItemTO} from "./app/modules/core/classes/menu.map.item.to";
import {MenuMapTO} from "./app/modules/core/classes/menu.map.to";
import {ServiceErrorMessage} from "./app/modules/core/classes/service.error.message";
import {UserInfoTO} from "./app/modules/core/classes/user.info.to";

export function loadTypes() {

    JacksonTypeFactory.registerType(AIDF);
    JacksonTypeFactory.registerType(AcaoFiscalTipoDocumento);
    JacksonTypeFactory.registerType(Administrador);
    JacksonTypeFactory.registerType(AgendaFiscalizacao);
    JacksonTypeFactory.registerType(Agendamento);
    JacksonTypeFactory.registerType(AlertaFiscalAliquotaSimplesIncorreta);
    JacksonTypeFactory.registerType(AlertaFiscalDivergenciaMovimentacaoReceita);
    JacksonTypeFactory.registerType(AlertaFiscalMovimentacaoAtipica);
    JacksonTypeFactory.registerType(AlertaFiscalQuedaArrecadacao);
    JacksonTypeFactory.registerType(AlertaFiscalRegimeSimplesIncorreto);
    JacksonTypeFactory.registerType(AlertaFiscalTributacaoExcessivaFora);
    JacksonTypeFactory.registerType(AlertaFiscal);
    JacksonTypeFactory.registerType(AliquotaProgressivaAtividadeExclui);
    JacksonTypeFactory.registerType(AliquotaProgressivaConfiguracao);
    JacksonTypeFactory.registerType(AliquotaProgressivaItem);
    JacksonTypeFactory.registerType(AliquotaProgressivaTabela);
    JacksonTypeFactory.registerType(AlunoCurso);
    JacksonTypeFactory.registerType(Aluno);
    JacksonTypeFactory.registerType(ArquivoImportacaoFiscal);
    JacksonTypeFactory.registerType(ArquivoImportacao);
    JacksonTypeFactory.registerType(Arquivo);
    JacksonTypeFactory.registerType(AtividadeCNAE);
    JacksonTypeFactory.registerType(AtividadeCTISS);
    JacksonTypeFactory.registerType(AtividadeDimensao);
    JacksonTypeFactory.registerType(AtividadeHistorico);
    JacksonTypeFactory.registerType(AtividadeTabelaIBPT);
    JacksonTypeFactory.registerType(Atividade);
    JacksonTypeFactory.registerType(AtividadeVencimento);
    JacksonTypeFactory.registerType(AutorizacaoImportacaoNFeGuia);
    JacksonTypeFactory.registerType(AutorizacaoImportacaoNFe);
    JacksonTypeFactory.registerType(AutorizacaoNFe);
    JacksonTypeFactory.registerType(BancoDependencia);
    JacksonTypeFactory.registerType(Banco);
    JacksonTypeFactory.registerType(BloqueioFaltaPagamentoCompetenciaIgnorada);
    JacksonTypeFactory.registerType(BloqueioMovimentacao);
    JacksonTypeFactory.registerType(CEP);
    JacksonTypeFactory.registerType(CalculoEncerramento);
    JacksonTypeFactory.registerType(CalculoGuiaAvulsa);
    JacksonTypeFactory.registerType(Cartorio);
    JacksonTypeFactory.registerType(CatalogoEndereco);
    JacksonTypeFactory.registerType(CodigoTipoTributacao);
    JacksonTypeFactory.registerType(CompetenciaDimensao);
    JacksonTypeFactory.registerType(Competencia);
    JacksonTypeFactory.registerType(Comunicado);
    JacksonTypeFactory.registerType(ConfigBoleto);
    JacksonTypeFactory.registerType(ConfigGuia);
    JacksonTypeFactory.registerType(ConfigLivro);
    JacksonTypeFactory.registerType(ConfigMunicipio);
    JacksonTypeFactory.registerType(ConfigNotaPremiadaDocumentosExcluidos);
    JacksonTypeFactory.registerType(ConfigNotaPremiadaPeriodo);
    JacksonTypeFactory.registerType(ConfigNotaPremiada);
    JacksonTypeFactory.registerType(ConfigRegimeTipoTributacao);
    JacksonTypeFactory.registerType(ConfigSolicitacoes);
    JacksonTypeFactory.registerType(ConfiguracaoPessoal);
    JacksonTypeFactory.registerType(ConsolidacaoDwLog);
    JacksonTypeFactory.registerType(Construtora);
    JacksonTypeFactory.registerType(ContaCosif);
    JacksonTypeFactory.registerType(Contador);
    JacksonTypeFactory.registerType(ControleNumeracaoGuia);
    JacksonTypeFactory.registerType(ControleNumeracaoNota);
    JacksonTypeFactory.registerType(ControleTSA);
    JacksonTypeFactory.registerType(Convite);
    JacksonTypeFactory.registerType(CorrecaoIndiceStrategy);
    JacksonTypeFactory.registerType(CorrecaoStrategy);
    JacksonTypeFactory.registerType(Cotacao);
    JacksonTypeFactory.registerType(CreditoAReceberAtividade);
    JacksonTypeFactory.registerType(CreditoAReceber);
    JacksonTypeFactory.registerType(CreditoTomadorConfig);
    JacksonTypeFactory.registerType(CreditoTomador);
    JacksonTypeFactory.registerType(Credito);
    JacksonTypeFactory.registerType(Curso);
    JacksonTypeFactory.registerType(CursoValor);
    JacksonTypeFactory.registerType(DadosCadastro);
    JacksonTypeFactory.registerType(DadosEndereco);
    JacksonTypeFactory.registerType(DadosPessoa);
    JacksonTypeFactory.registerType(Debito);
    JacksonTypeFactory.registerType(DeclaracaoIncorretaSimples);
    JacksonTypeFactory.registerType(DeclaracaoNF);
    JacksonTypeFactory.registerType(DeducaoSolicitacaoAlteracaoNFe);
    JacksonTypeFactory.registerType(Deducao);
    JacksonTypeFactory.registerType(Denuncia);
    JacksonTypeFactory.registerType(DesifApuracaoMensalConsolidado);
    JacksonTypeFactory.registerType(DesifApuracaoMensalDependencia);
    JacksonTypeFactory.registerType(DesifApuracaoMensalSubtitulo);
    JacksonTypeFactory.registerType(DesifApuracaoMensal);
    JacksonTypeFactory.registerType(DesifCodigoTributacaoAtividade);
    JacksonTypeFactory.registerType(DesifCodigoTributacao);
    JacksonTypeFactory.registerType(DesifConta);
    JacksonTypeFactory.registerType(DesifDemonstrativoContabilBalanceteAnalitico);
    JacksonTypeFactory.registerType(DesifDemonstrativoContabilDependencia);
    JacksonTypeFactory.registerType(DesifDemonstrativoContabilRateioInterno);
    JacksonTypeFactory.registerType(DesifDemonstrativoContabil);
    JacksonTypeFactory.registerType(DesifDemonstrativoLancamentoContabil);
    JacksonTypeFactory.registerType(DesifEventoContabil);
    JacksonTypeFactory.registerType(DesifImportacaoArquivo);
    JacksonTypeFactory.registerType(DesifPlanoConta);
    JacksonTypeFactory.registerType(DesifServicoVariavel);
    JacksonTypeFactory.registerType(DesifTarifa);
    JacksonTypeFactory.registerType(DesifTarifaVariavel);
    JacksonTypeFactory.registerType(DiaDimensao);
    JacksonTypeFactory.registerType(Documento);
    JacksonTypeFactory.registerType(Duvida);
    JacksonTypeFactory.registerType(Empresa);
    JacksonTypeFactory.registerType(EncerramentoAutomaticoDetalhamento);
    JacksonTypeFactory.registerType(EncerramentoAutomatico);
    JacksonTypeFactory.registerType(EncerramentoDesconto);
    JacksonTypeFactory.registerType(EncerramentoDocumento);
    JacksonTypeFactory.registerType(EncerramentoMemoria);
    JacksonTypeFactory.registerType(Encerramento);
    JacksonTypeFactory.registerType(Endereco);
    JacksonTypeFactory.registerType(Ensino);
    JacksonTypeFactory.registerType(ErroImportWebISS);
    JacksonTypeFactory.registerType(EscritBanco);
    JacksonTypeFactory.registerType(EscritCartorio);
    JacksonTypeFactory.registerType(EscritEspecial);
    JacksonTypeFactory.registerType(EscritPapel);
    JacksonTypeFactory.registerType(EscritPedagio);
    JacksonTypeFactory.registerType(EscritTransporteArquivo);
    JacksonTypeFactory.registerType(EscritTransporteTarifa);
    JacksonTypeFactory.registerType(EscritTransporteTarifaValor);
    JacksonTypeFactory.registerType(EscritTransporte);
    JacksonTypeFactory.registerType(EscrituracaoObraUnica);
    JacksonTypeFactory.registerType(EspecieAIDF);
    JacksonTypeFactory.registerType(Estado);
    JacksonTypeFactory.registerType(EstatisticaBancaria);
    JacksonTypeFactory.registerType(EstatisticaVerbete);
    JacksonTypeFactory.registerType(Evento);
    JacksonTypeFactory.registerType(ExigibilidadePadrao);
    JacksonTypeFactory.registerType(FaixaMultaStrategy);
    JacksonTypeFactory.registerType(FaixaMulta);
    JacksonTypeFactory.registerType(FatoGeradorCreditoAReceber);
    JacksonTypeFactory.registerType(Feriado);
    JacksonTypeFactory.registerType(Fiscal);
    JacksonTypeFactory.registerType(FuncionarioEmpresas);
    JacksonTypeFactory.registerType(Funcionario);
    JacksonTypeFactory.registerType(Gestor);
    JacksonTypeFactory.registerType(Grafica);
    JacksonTypeFactory.registerType(GrupoAtividade);
    JacksonTypeFactory.registerType(GuiaAvulsaConfigBoleto);
    JacksonTypeFactory.registerType(GuiaAvulsa);
    JacksonTypeFactory.registerType(GuiaMensagem);
    JacksonTypeFactory.registerType(IBPTArquivo);
    JacksonTypeFactory.registerType(IBPTAtividade);
    JacksonTypeFactory.registerType(IBPTTabela);
    JacksonTypeFactory.registerType(IBPTVinculoAtividade);
    JacksonTypeFactory.registerType(ImportacaoDWMetaDado);
    JacksonTypeFactory.registerType(ImportacaoPGDASD);
    JacksonTypeFactory.registerType(Indice);
    JacksonTypeFactory.registerType(Infracao);
    JacksonTypeFactory.registerType(InstituicaoBancaria);
    JacksonTypeFactory.registerType(IsencaoGuia);
    JacksonTypeFactory.registerType(ItemEscrit);
    JacksonTypeFactory.registerType(ItemGuiaAvulsa);
    JacksonTypeFactory.registerType(ItemNotaFiscalHistorico);
    JacksonTypeFactory.registerType(ItemNotaFiscal);
    JacksonTypeFactory.registerType(ItemReciboLocacao);
    JacksonTypeFactory.registerType(ItemSolicitacaoAlteracaoNFe);
    JacksonTypeFactory.registerType(ItemTabelaIBPT);
    JacksonTypeFactory.registerType(JuroDiarioStrategy);
    JacksonTypeFactory.registerType(JuroMensalStrategy);
    JacksonTypeFactory.registerType(JuroSelicStrategy);
    JacksonTypeFactory.registerType(JurosStrategy);
    JacksonTypeFactory.registerType(JustificativaIndeferimento);
    JacksonTypeFactory.registerType(LogImportacao);
    JacksonTypeFactory.registerType(Logradouro);
    JacksonTypeFactory.registerType(LoteRps);
    JacksonTypeFactory.registerType(LoteTre);
    JacksonTypeFactory.registerType(MediaMovimentacao);
    JacksonTypeFactory.registerType(Mensagem);
    JacksonTypeFactory.registerType(MultaDiariaStrategy);
    JacksonTypeFactory.registerType(MultaInfracao);
    JacksonTypeFactory.registerType(MultaStrategy);
    JacksonTypeFactory.registerType(MunicipioCertificado);
    JacksonTypeFactory.registerType(MunicipioDimensao);
    JacksonTypeFactory.registerType(Municipio);
    JacksonTypeFactory.registerType(MunicipioVencimento);
    JacksonTypeFactory.registerType(NotaFiscalGuiaAvulsa);
    JacksonTypeFactory.registerType(NotaFiscalHistorico);
    JacksonTypeFactory.registerType(NotaFiscalOrigem);
    JacksonTypeFactory.registerType(NotaFiscal);
    JacksonTypeFactory.registerType(NotaPremiada);
    JacksonTypeFactory.registerType(NotificacaoSistema);
    JacksonTypeFactory.registerType(ObraCorrecaoNotaFiscal);
    JacksonTypeFactory.registerType(ObraNotaFiscal);
    JacksonTypeFactory.registerType(ObraPrestador);
    JacksonTypeFactory.registerType(Obra);
    JacksonTypeFactory.registerType(ObraUnica);
    JacksonTypeFactory.registerType(OcorrenciaTermoAutoInfracao);
    JacksonTypeFactory.registerType(OrdemServicoArquivo);
    JacksonTypeFactory.registerType(OrdemServicoItem);
    JacksonTypeFactory.registerType(OrdemServico);
    JacksonTypeFactory.registerType(PAGPagamento);
    JacksonTypeFactory.registerType(PGDASDApuracaoFilialAtividadeDetalhe);
    JacksonTypeFactory.registerType(PGDASDApuracaoFilialAtividade);
    JacksonTypeFactory.registerType(PGDASDApuracaoFilial);
    JacksonTypeFactory.registerType(PGDASDApuracaoReceita);
    JacksonTypeFactory.registerType(PGDASDApuracao);
    JacksonTypeFactory.registerType(PGDASDDASDetalhe);
    JacksonTypeFactory.registerType(PGDASDDAS);
    JacksonTypeFactory.registerType(PagamentoSIAFI);
    JacksonTypeFactory.registerType(Pagamento);
    JacksonTypeFactory.registerType(Pais);
    JacksonTypeFactory.registerType(Papel);
    JacksonTypeFactory.registerType(ParcelamentoSimplesConsolidacao);
    JacksonTypeFactory.registerType(ParcelamentoSimplesDebito);
    JacksonTypeFactory.registerType(ParcelamentoSimplesPagamento);
    JacksonTypeFactory.registerType(ParcelamentoSimples);
    JacksonTypeFactory.registerType(Pedagio);
    JacksonTypeFactory.registerType(PerfilDescontoBaseCalculo);
    JacksonTypeFactory.registerType(PerfilDescontoImpostoNfe);
    JacksonTypeFactory.registerType(PessoaDescontoEncerramento);
    JacksonTypeFactory.registerType(PessoaExigibilidade);
    JacksonTypeFactory.registerType(PessoaMigracaoGrp);
    JacksonTypeFactory.registerType(PessoaPerfilDescontoBaseCalculo);
    JacksonTypeFactory.registerType(PessoaPerfilDescontoImpostoNfe);
    JacksonTypeFactory.registerType(Pessoa);
    JacksonTypeFactory.registerType(Preferencias);
    JacksonTypeFactory.registerType(ProcessoAssincrono);
    JacksonTypeFactory.registerType(ProcessoExportacaoSimplesDetalhe);
    JacksonTypeFactory.registerType(ProcessoExportacaoSimples);
    JacksonTypeFactory.registerType(ProcessoMetadata);
    JacksonTypeFactory.registerType(ReceitaRealizadaAtividade);
    JacksonTypeFactory.registerType(ReceitaRealizada);
    JacksonTypeFactory.registerType(ReciboLocacao);
    JacksonTypeFactory.registerType(Recibo);
    JacksonTypeFactory.registerType(RegimeCompetencia);
    JacksonTypeFactory.registerType(RegimeISS);
    JacksonTypeFactory.registerType(RelacaoNotaEscrit);
    JacksonTypeFactory.registerType(RelacaoObraNotaEscrit);
    JacksonTypeFactory.registerType(Relacionamento);
    JacksonTypeFactory.registerType(RelatorioAssincrono);
    JacksonTypeFactory.registerType(RelatorioMetadata);
    JacksonTypeFactory.registerType(Relatorios);
    JacksonTypeFactory.registerType(Responsavel);
    JacksonTypeFactory.registerType(RotinaAgendamentoLog);
    JacksonTypeFactory.registerType(RotinaAgendamentoParametro);
    JacksonTypeFactory.registerType(RotinaAgendamento);
    JacksonTypeFactory.registerType(SaldoCompetencia);
    JacksonTypeFactory.registerType(Serie);
    JacksonTypeFactory.registerType(ServicoBancario);
    JacksonTypeFactory.registerType(ServicoEspecialGrupo);
    JacksonTypeFactory.registerType(ServicoEspecial);
    JacksonTypeFactory.registerType(ServicoEspecialValor);
    JacksonTypeFactory.registerType(SituacaoItem);
    JacksonTypeFactory.registerType(SolicitacaoAIDF);
    JacksonTypeFactory.registerType(SolicitacaoAbatimentoObra);
    JacksonTypeFactory.registerType(SolicitacaoAlteracaoNFe);
    JacksonTypeFactory.registerType(SolicitacaoArquivo);
    JacksonTypeFactory.registerType(SolicitacaoAutenticacaoLivroFiscal);
    JacksonTypeFactory.registerType(SolicitacaoCadastroEventual);
    JacksonTypeFactory.registerType(SolicitacaoComJustificativa);
    JacksonTypeFactory.registerType(SolicitacaoDadosPessoa);
    JacksonTypeFactory.registerType(SolicitacaoGenerica);
    JacksonTypeFactory.registerType(SolicitacaoImportacaoNFE);
    JacksonTypeFactory.registerType(SolicitacaoNFe);
    JacksonTypeFactory.registerType(SolicitacaoPerfilDescontoNfe);
    JacksonTypeFactory.registerType(SolicitacaoSenha);
    JacksonTypeFactory.registerType(Solicitacao);
    JacksonTypeFactory.registerType(SolicitacaoUsuario);
    JacksonTypeFactory.registerType(Suporte);
    JacksonTypeFactory.registerType(TNCAgendamentoExecLog);
    JacksonTypeFactory.registerType(TNCAgendamento);
    JacksonTypeFactory.registerType(TNCAlertaMigracao);
    JacksonTypeFactory.registerType(TNCCadastroMigracaoPendente);
    JacksonTypeFactory.registerType(TNCError);
    JacksonTypeFactory.registerType(TNCGlobalConfig);
    JacksonTypeFactory.registerType(TNCGlobalScript);
    JacksonTypeFactory.registerType(TNCMunicipioConfig);
    JacksonTypeFactory.registerType(TNCPropriedade);
    JacksonTypeFactory.registerType(TNCSignal);
    JacksonTypeFactory.registerType(TNCStatus);
    JacksonTypeFactory.registerType(TNCSyncScript);
    JacksonTypeFactory.registerType(TabelaSimplesItem);
    JacksonTypeFactory.registerType(TabelaSimples);
    JacksonTypeFactory.registerType(Tarefa);
    JacksonTypeFactory.registerType(Telefone);
    JacksonTypeFactory.registerType(TermoAutoInfracao);
    JacksonTypeFactory.registerType(TermoEncerramentoFiscalizacao);
    JacksonTypeFactory.registerType(TermoExclusaoSimples);
    JacksonTypeFactory.registerType(TermoInicioFiscalizacao);
    JacksonTypeFactory.registerType(TextoTermoFiscal);
    JacksonTypeFactory.registerType(TipoAbatimentoObraContribuinte);
    JacksonTypeFactory.registerType(TipoAbatimentoObra);
    JacksonTypeFactory.registerType(TipoBloqueioMovimentacao);
    JacksonTypeFactory.registerType(TipoCredito);
    JacksonTypeFactory.registerType(TipoDeducao);
    JacksonTypeFactory.registerType(TipoDescontoBaseCalculo);
    JacksonTypeFactory.registerType(TipoEstabelecimentoCartorio);
    JacksonTypeFactory.registerType(TipoServicoCartorio);
    JacksonTypeFactory.registerType(TipoTributacao);
    JacksonTypeFactory.registerType(AlertaFiscalTO);
    JacksonTypeFactory.registerType(AliquotaSimplesTO);
    JacksonTypeFactory.registerType(AtendimentoTO);
    JacksonTypeFactory.registerType(AtividadeTO);
    JacksonTypeFactory.registerType(BilheteNotaPremiadaContribuinteDetalheTO);
    JacksonTypeFactory.registerType(BilheteNotaPremiadaContribuinteTO);
    JacksonTypeFactory.registerType(BilheteNotaPremiadaPeriodoTO);
    JacksonTypeFactory.registerType(BilheteNotaPremiadaTO);
    JacksonTypeFactory.registerType(CalculoCorrecaoTO);
    JacksonTypeFactory.registerType(CalculoGuiaAvulsaTO);
    JacksonTypeFactory.registerType(CancelaNotaLoteTO);
    JacksonTypeFactory.registerType(CatalogoEnderecoTO);
    JacksonTypeFactory.registerType(ConfigNotaPremiadaPeriodoTO);
    JacksonTypeFactory.registerType(ConfigNotaPremiadaTO);
    JacksonTypeFactory.registerType(ConfiguracaoAcessoTO);
    JacksonTypeFactory.registerType(ConfiguracaoBackupTO);
    JacksonTypeFactory.registerType(ConfiguracaoConstrucaoCivilTO);
    JacksonTypeFactory.registerType(ConfiguracaoContribuinteTO);
    JacksonTypeFactory.registerType(ConfiguracaoMenuTO);
    JacksonTypeFactory.registerType(ConfiguracaoMigradorTO);
    JacksonTypeFactory.registerType(ConfiguracaoMunicipioTO);
    JacksonTypeFactory.registerType(ConfiguracaoNotificacaoTO);
    JacksonTypeFactory.registerType(ConfiguracaoSerieTO);
    JacksonTypeFactory.registerType(ConfiguracaoTipoTributacaoTO);
    JacksonTypeFactory.registerType(ConfiguracaoUnidadeTO);
    JacksonTypeFactory.registerType(ConsultaContribuinteAutorizacoesTO);
    JacksonTypeFactory.registerType(ConsultaContribuinteLivrosTO);
    JacksonTypeFactory.registerType(ConsultaContribuinteNotaEscritTO);
    JacksonTypeFactory.registerType(ConsultaContribuinteTO);
    JacksonTypeFactory.registerType(ConsultaDASTO);
    JacksonTypeFactory.registerType(ConsultaNotaFiscalHistoricoTO);
    JacksonTypeFactory.registerType(ConsultaSimplesNacionalResumoAtividadeTO);
    JacksonTypeFactory.registerType(ConsultaSimplesNacionalTO);
    JacksonTypeFactory.registerType(ContribuinteEncerramentoResumidoTO);
    JacksonTypeFactory.registerType(ControleExportacaoTO);
    JacksonTypeFactory.registerType(CreditoTO);
    JacksonTypeFactory.registerType(CreditosTO);
    JacksonTypeFactory.registerType(CursoTO);
    JacksonTypeFactory.registerType(EncerramentoAnoMesTO);
    JacksonTypeFactory.registerType(EncerramentoAutomaticoTO);
    JacksonTypeFactory.registerType(EncerramentoDetalheMesTO);
    JacksonTypeFactory.registerType(EncerramentoDetalheTO);
    JacksonTypeFactory.registerType(EncerramentoMesTO);
    JacksonTypeFactory.registerType(EncerramentoSimplificadoTO);
    JacksonTypeFactory.registerType(EncerramentosAutomaticoDetalhamentoTO);
    JacksonTypeFactory.registerType(EncerramentosAutomaticoDetalheTO);
    JacksonTypeFactory.registerType(EncerramentoOficioDetalheTO);
    JacksonTypeFactory.registerType(EncerramentoOficioTO);
    JacksonTypeFactory.registerType(EscritCartorioTO);
    JacksonTypeFactory.registerType(EscritPedagioTO);
    JacksonTypeFactory.registerType(EscritTransporteArquivoResumoTO);
    JacksonTypeFactory.registerType(EscritTransporteArquivoTO);
    JacksonTypeFactory.registerType(EscritTransporteTarifaTO);
    JacksonTypeFactory.registerType(EscrituracaoTO);
    JacksonTypeFactory.registerType(EstatisticaAcessoTO);
    JacksonTypeFactory.registerType(ExportTO);
    JacksonTypeFactory.registerType(FaixaValoresRecebidosSimplesNacionalTO);
    JacksonTypeFactory.registerType(FiscalMenuTO);
    JacksonTypeFactory.registerType(GuiaMesTO);
    JacksonTypeFactory.registerType(GuiaSimplificadaTO);
    JacksonTypeFactory.registerType(InicialMenuTO);
    JacksonTypeFactory.registerType(InstanceMonitorTO);
    JacksonTypeFactory.registerType(MonitorInstituicaoEnsinoTO);
    JacksonTypeFactory.registerType(MonitorIntegracaoPortalNacionalTO);
    JacksonTypeFactory.registerType(MonitorMunicipioTabelaTO);
    JacksonTypeFactory.registerType(MonitorMunicipioTO);
    JacksonTypeFactory.registerType(MonitorNotaPremiadaPrestadoresTO);
    JacksonTypeFactory.registerType(MonitorNotaPremiadaTO);
    JacksonTypeFactory.registerType(NotaDeducaoDocumentoTO);
    JacksonTypeFactory.registerType(NotaDeducaoTO);
    JacksonTypeFactory.registerType(NotaEnsinoAlunoTO);
    JacksonTypeFactory.registerType(NotaEnsinoTO);
    JacksonTypeFactory.registerType(NotaEscritMesTO);
    JacksonTypeFactory.registerType(NotaEscritTO);
    JacksonTypeFactory.registerType(NotaFiscalSimplificadaTO);
    JacksonTypeFactory.registerType(NotaFiscalTO);
    JacksonTypeFactory.registerType(OrdemServicoTO);
    JacksonTypeFactory.registerType(ParcelamentoConsolidacaoTO);
    JacksonTypeFactory.registerType(ParcelamentoSimplesTO);
    JacksonTypeFactory.registerType(PessoaDescontoEncerramentoTO);
    JacksonTypeFactory.registerType(PessoaSimplesTO);
    JacksonTypeFactory.registerType(PessoaSimplificadaTO);
    JacksonTypeFactory.registerType(PGDASDApuracaoTO);
    JacksonTypeFactory.registerType(PGDASDDASTO);
    JacksonTypeFactory.registerType(PgdasdTO);
    JacksonTypeFactory.registerType(PrimeiroAcessoTO);
    JacksonTypeFactory.registerType(ProcessoExportacaoSimplesTO);
    JacksonTypeFactory.registerType(ReciboLocacaoTO);
    JacksonTypeFactory.registerType(ReciboTO);
    JacksonTypeFactory.registerType(RotinaAgendamentoTO);
    JacksonTypeFactory.registerType(SaldoCompetenciaTO);
    JacksonTypeFactory.registerType(SIAFIPagamentosCompetenciaTO);
    JacksonTypeFactory.registerType(SIAFIPagamentosTO);
    JacksonTypeFactory.registerType(SolicitacaoAIDFTO);
    JacksonTypeFactory.registerType(SolicitacaoAlteracaoNFeTO);
    JacksonTypeFactory.registerType(SolicitacaoArquivoTO);
    JacksonTypeFactory.registerType(SolicitacaoAutenticacaoLivroFiscalTO);
    JacksonTypeFactory.registerType(SolicitacaoCancelamentoEncerramentoTO);
    JacksonTypeFactory.registerType(SolicitacaoCancelamentoNFeTO);
    JacksonTypeFactory.registerType(SolicitacaoImportacaoNFETO);
    JacksonTypeFactory.registerType(SolicitacaoIndeferidaTO);
    JacksonTypeFactory.registerType(SolicitacaoNFeTO);
    JacksonTypeFactory.registerType(SqlInterativoRow);
    JacksonTypeFactory.registerType(SqlInterativoTitle);
    JacksonTypeFactory.registerType(SqlInterativoTO);
    JacksonTypeFactory.registerType(SqlInterativoValorTO);
    JacksonTypeFactory.registerType(TNCAgendamentoExecTO);
    JacksonTypeFactory.registerType(TNCDocumentoExportadosTO);
    JacksonTypeFactory.registerType(TNCDocumentoPendentesTO);
    JacksonTypeFactory.registerType(TopicoTO);
    JacksonTypeFactory.registerType(UsuarioTO);
    JacksonTypeFactory.registerType(VinculoAtividadeAnexoSimplesTO);
    JacksonTypeFactory.registerType(Topico);
    JacksonTypeFactory.registerType(Transporte);
    JacksonTypeFactory.registerType(UnidadeItem);
    JacksonTypeFactory.registerType(Usuario);
    JacksonTypeFactory.registerType(ValorFolhaPagamento);
    JacksonTypeFactory.registerType(ValorTabelaIBPT);
    JacksonTypeFactory.registerType(Verbete);
    JacksonTypeFactory.registerType(VinculoAtividadeCNAE);
    JacksonTypeFactory.registerType(VinculoAtividade);
    JacksonTypeFactory.registerType(VinculoCnaeCtiss);
    JacksonTypeFactory.registerType(VinculoDocumento);
    JacksonTypeFactory.registerType(VinculoPessoaBancoDesif);
    JacksonTypeFactory.registerType(WebServiceArquivo);
    JacksonTypeFactory.registerType(AuthErrorTO);
    JacksonTypeFactory.registerType(AuthResponseTO);
    JacksonTypeFactory.registerType(ExceptionInfo);
    JacksonTypeFactory.registerType(MenuMapItemTO);
    JacksonTypeFactory.registerType(MenuMapTO);
    JacksonTypeFactory.registerType(ServiceErrorMessage);
    JacksonTypeFactory.registerType(UserInfoTO);

}